exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-discography-index-js": () => import("./../../../src/pages/discography/index.js" /* webpackChunkName: "component---src-pages-discography-index-js" */),
  "component---src-pages-discography-markdown-remark-fields-slug-js": () => import("./../../../src/pages/discography/{MarkdownRemark.fields__slug}.js" /* webpackChunkName: "component---src-pages-discography-markdown-remark-fields-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tracks-index-js": () => import("./../../../src/pages/tracks/index.js" /* webpackChunkName: "component---src-pages-tracks-index-js" */),
  "component---src-pages-tracks-markdown-remark-fields-slug-js": () => import("./../../../src/pages/tracks/{MarkdownRemark.fields__slug}.js" /* webpackChunkName: "component---src-pages-tracks-markdown-remark-fields-slug-js" */)
}

